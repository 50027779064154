import React, { useContext } from 'react';
import { Tabs } from 'antd';
import { orderTabKeyMap } from './order-column-keymap';
import AllOrderList from './order-all';
import OrderNeedSyncToSfr from './order-need-sync-to-sfr';
import OrderNeedSyncToErp from './order-need-sync-to-erp';
import { UserContext } from '../../App.js';

const OrderList = () => {
  const userContext = useContext(UserContext);
  const items = [
    {
      key: '1',
      label: `${orderTabKeyMap['all']}`,
      children: <AllOrderList />,
      disabled: false
    },
    {
      key: '2',
      label: `${orderTabKeyMap['orderNeedSyncToSfr']}`,
      children: <OrderNeedSyncToSfr />,
      disabled: false
    },
    {
      key: '3',
      label: `${orderTabKeyMap['orderNeedSyncToErp']}`,
      children: <OrderNeedSyncToErp />,
      disabled: false
    },
  ];

  return (
    <>
      {
        userContext.tenantId === 'joker-e5k7x'
        ?
          <Tabs defaultActiveKey="1" items={items}/>
        :
          <AllOrderList />
      }
    </>
  );
};

export default OrderList;
