import React from 'react';
import { Tag } from 'antd';

export const orderColumnKeyMap = {
  status: (val) => { return (val === '3' ? <Tag color="#87d068"> 已完工 </Tag> : val === '1' ? <Tag color="rgb( 191, 189, 194)"> 未開工 </Tag> : <Tag color="#2db7f5"> 進行中 </Tag>) },
  yield: (text, record) => (<div> {text} % </div>),
  isActive: (val) => { return (val ? '是' : '否') },
  isASAP: (val) => { return (val ? '是' : '否') }
};

export const orderTabKeyMap = {
  all: '全部',
  orderNeedSyncToSfr: '未同步到SFR',
  orderNeedSyncToErp: '未同步到ERP'
}

export const searchBarKeyMap = {
  search: '查詢',
  estimatedTime: '原應開工日',
  workOrderId: '工單單號',
  ordTrackId: '批號'
}

export const reasonKeyMap = {
  notReceivedEnoughQty: '領料不足',
  outsourcingWorkOrder: '託外無法回填ERP',
  woohEndTimeError: '小工單完工時間異常',
  woEndTimeError: '大工單完工時間異常'
}

export const workOrderStatus = {
  X: '無需同步',
  N: '未同步至SFR',
  R: '未同步至SFR',
  needUpdateWoohToSfr: '未更新到SFR',
  Y: '已同步至SFR',
  needSyncERP: '未同步至ERP',
  alreadySyncERP: '未同步至ERP',
  cannotSyncToCus: '託外無法回填ERP'
}