import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';
import { Table, Divider, Badge, message, Button, Col, Row, Input } from 'antd';
import { getOrdersByKey, uploadWorkOrderToDZ } from '../../api/request.js';
import dayjs from 'dayjs';
import { UserContext } from '../../App.js';
import FailedList from './failed-list.js';
import DayjsDatePicker from '../common/dayjs-date-picker';
import { searchBarKeyMap } from './order-column-keymap.js';

const OrderNeedSyncToSfr = () => {
  const twoMonthsAgo = dayjs().subtract(2, 'month').startOf('date').toISOString();
  const currentDate = dayjs().toISOString();

  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderColumns, setOrderColumns] = useState([{}]);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [failedListVisible, setFailedListVisible] = useState(false);
  const [uploadResultList, setUploadResultList] = useState([]);
  const [dateForShow, setDateForShow] = useState([dayjs(twoMonthsAgo), dayjs(currentDate)]);

  const searchParams = useRef({ estimatedTimeStart: twoMonthsAgo, estimatedTimeEnd: currentDate });
  const mapRef = useRef({});
  const ordersRef = useRef([]);
  const updateFlag = useRef(false);

  const getOrders = useCallback(async () => {
    let params = Object.assign({}, searchParams.current, { tenantId: userContext.tenantId, sort: 'default' });
    setLoading(true);
    try {
      let res = await getOrdersByKey('needSyncToSfr', params);
      let findDisplayStatusIndex = res.orderColumns.findIndex((item) => {
        return item.dataIndex === 'displayStatus';
      });
      if (findDisplayStatusIndex !== -1) {
        res.orderColumns[findDisplayStatusIndex] = Object.assign({}, res.orderColumns[findDisplayStatusIndex], {
          render: (val) => {
            switch (val) {
              case "N":
                return <Badge status="default" text="未同步到SFR" />
              case "R":
                return <Badge status="default" text="未同步到SFR" />
              case "needUpdateWoohToSfr":
                return <Badge status="default" text="未更新到SFR" />
              case "Y":
                return <Badge status="success" text="已同步到SFR" />
              default:
                return null;
            }
          }
        });
      }

      for (const index in res.orderColumns) {
        if (res.orderColumns[index].timeFlag === 'true') {
          res.orderColumns[index].render = (val) => {
            return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD') : ''
          };
        }
      }

      res.orders.data = res.orders.data.map((item, index) => {
        return Object.assign({}, item, { key: index });
      });
      setShowCheckBox(true);
      setOrders(res.orders.data);
      setOrderColumns(res.orderColumns);
      setLoading(false);
      mapRef.current = res.dzDataMap;
    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('order-need-sync-to-sfr - getOrders', e)
    }
  }, [userContext.tenantId]);

  useEffect(() => {
    getOrders();
  }, [getOrders])

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = { // 勾選的CheckBox
    selectedRowKeys,
    onChange: onSelectChange
  };

  const handleUpload = async () => {
    setLoading(true);
    updateFlag.current = true;

    let newOrders = Array.from(orders);
    ordersRef.current = newOrders;
    let needUpload = [];
    let updateDataMap = {}
    for (let rowIndex = 0; rowIndex < selectedRows.length; rowIndex++) {
      // 一個一個查是否上傳過
      let row = selectedRows[rowIndex];

      if (row.displayStatus === 'Y' || row.displayStatus === 'alreadySyncERP') {
        message.warning(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} 工單已同步過`);
        continue;
      } else if (row.displayStatus === 'X') {
        message.error(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} 工單無需同步`);
        continue;
      } else if (row.displayStatus === 'cannotSyncToCus') { // 託外
        message.error(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} 託外工單無法回填ERP`);
        continue;
      } else {
        if (row.route.length === 0) {
          message.warning(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} ERP工單資料異常`);
        } else {
          needUpload.push(row);
          if (mapRef.current.hasOwnProperty(row.woId)) {
            updateDataMap[row.woId] = mapRef.current[row.woId];
          }
        }
      }
    }
    if (needUpload.length > 0) {
      // 全部丟給後端處理
      let uploadResult = null;
      try {
        uploadResult = await uploadWorkOrderToDZ({
          selectedWorkOrders: needUpload,
          dzDataMap: updateDataMap,
          tenantId: userContext.tenantId
        });
      } catch (e) {
        console.log('Order - handleUpload', e)
        message.error({
          content: `上傳失敗`
        });

        uploadResult = {
          success: [],
          failed: []
        }
        needUpload.forEach((item) => {
          uploadResult.failed.push(item.woId);
        });
        setUploadResultList(uploadResult);
        setFailedListVisible(true);
      }

      if (uploadResult !== null) {
        if (uploadResult.success.length !== 0) {
          for (const woId of uploadResult.success) {
            let index = newOrders.findIndex((element) => {
              return element.woId === woId
            })
            let newUploadOrder = '';
            switch (newOrders[index].displayStatus) {
              case 'N':
                newUploadOrder = 'Y';
                break;
              case 'R':
                newUploadOrder = 'Y';
                break;
              case 'needSyncERP':
                newUploadOrder = 'alreadySyncERP';
                break;
              case 'needUpdateWoohToSfr':
                newUploadOrder = 'Y';
                break;
              case 'cannotSyncToCus': // 託外
                newUploadOrder = 'Y';
                break;
              default:
                newUploadOrder = newOrders[index].displayStatus;
            }
            let newData = Object.assign({}, newOrders[index], { displayStatus: newUploadOrder })
            newOrders.splice(index, 1, newData);
          }

          if (uploadResult.failed.length === 0) {
            // 全成功
            message.success({
              content: `上傳成功`
            });
          } else {
            // 一半成功 一半失敗
            setUploadResultList(uploadResult);
            setFailedListVisible(true);
          }
        } else {
          // 全失敗
          if (uploadResult.failed.length !== 0) {
            message.error({
              content: `上傳失敗`
            });
            setUploadResultList(uploadResult);
            setFailedListVisible(true);
          }
        }
      }
    }
    setOrders(newOrders);
    setLoading(false);
    setSelectedRowKeys([]);
  }

  const showErrorMessage = () => {
    message.error('網路異常，請稍後重試。');
  }

  const handleMultipleDateSearchOnChange = (date, dateString) => {
    if (date !== null) {
      const timeStart = dateString
        ? dayjs(dateString[0]).startOf("day").toISOString()
        : undefined;
      const timeEnd = dateString
        ? dayjs(dateString[1]).endOf("day").toISOString()
        : undefined;

      searchParams.current = Object.assign({}, searchParams.current, { estimatedTimeStart: timeStart, estimatedTimeEnd: timeEnd });
      setDateForShow([dayjs(timeStart), dayjs(timeEnd)]);
    } else {
      if (searchParams.current.hasOwnProperty("estimatedTimeStart")) {
        delete searchParams.current["estimatedTimeStart"];
        delete searchParams.current["estimatedTimeEnd"];
      }
      setDateForShow([]);
    }
  }

  const handleMultipleInputSearchOnChange = (e, type) => {
    let value = e.target.value.trim();
    if (value !== '') {
      switch (type) {
        case "workOrderId":
          searchParams.current = Object.assign({}, searchParams.current, { workOrderId: value });
          break;
        case "ordTrackId":
          searchParams.current = Object.assign({}, searchParams.current, { ordTrackId: value });
          break;
        default:
          break;
      }
    } else {
      if (type === "workOrderId" && searchParams.current.hasOwnProperty("workOrderId")) {
        delete searchParams.current["workOrderId"];
      }

      if (type === "ordTrackId" && searchParams.current.hasOwnProperty("ordTrackId")) {
        delete searchParams.current["ordTrackId"];
      }
    }
  }

  const handleMultipleSearchOnClick = async () => {
    try {
      if (Object.keys(searchParams.current).length === 0) {
        searchParams.current = { estimatedTimeStart: twoMonthsAgo, estimatedTimeEnd: currentDate };
        setDateForShow([dayjs(twoMonthsAgo), dayjs(currentDate)]);
      }
      getOrders();
    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('order-need-sync-to-sfr - handleMultipleSearchOnClick', e)
    }
  }

  return (
    <>
      <Row gutter={[8, 8]} style={{ marginTop: '12px', marginBottom: '12px' }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{searchBarKeyMap['estimatedTime']}</div>
            <div>
              <DayjsDatePicker.RangePicker
                size="large"
                bordered={false}
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                }}
                allowClear
                placeholder={['開始時間', '結束時間']}
                value={dateForShow}
                onChange={(date, dateString) => handleMultipleDateSearchOnChange(date, dateString, "estimatedTime")}
                ranges={{
                  昨天: [
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day"),
                    dayjs()
                      .subtract(1, "day")
                      .endOf("day"),
                  ],
                  今天: [
                    dayjs().startOf("day"),
                    dayjs().endOf("day"),
                  ],
                  本週: [
                    dayjs().startOf("week"),
                    dayjs().endOf("week"),
                  ],
                  上週: [
                    dayjs()
                      .subtract(1, "week")
                      .startOf("week"),
                    dayjs()
                      .subtract(1, "week")
                      .endOf("week"),
                  ],
                  本月: [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  前一個月: [
                    dayjs()
                      .subtract(1, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前二個月: [
                    dayjs()
                      .subtract(2, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前三個月: [
                    dayjs()
                      .subtract(3, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{searchBarKeyMap['workOrderId']}</div>
            <div style={{ width: '100%' }}>
              <Input
                allowClear
                bordered={false}
                onChange={(e) => handleMultipleInputSearchOnChange(e, 'workOrderId')}
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{searchBarKeyMap['ordTrackId']}</div>
            <div style={{ width: '100%' }}>
              <Input
                allowClear
                bordered={false}
                onChange={(e) => handleMultipleInputSearchOnChange(e, 'ordTrackId')}
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        </Col>

        <Button
          onClick={handleMultipleSearchOnClick}
          type="primary"
        >
          {searchBarKeyMap['search']}
        </Button>
      </Row>

      <Divider />
      {showCheckBox && selectedRows.length > 0
        ?
        <Button type="primary" onClick={handleUpload}> 上傳工單 </Button>
        :
        null
      }
      <Table
        dataSource={orders}
        columns={orderColumns}
        loading={loading}
        rowSelection={rowSelection}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `共 ${total} 筆`
        }}
      />
      <FailedList
        uploadResultList={uploadResultList}
        failedListVisible={failedListVisible}
        setFailedListVisible={setFailedListVisible}
      />
    </>
  )
}
export default OrderNeedSyncToSfr;