import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Divider, Table, message, Button, Pagination, Row, Col, Input } from 'antd';
import DayjsDatePicker from '../common/dayjs-date-picker';
import { getUnusuals, updateUnusualResolveTime, getUnusualsBySearch } from '../../api/request';
import { UserContext } from '../../App.js';
import dayjs from 'dayjs';
import { unusualKeyMap } from '../../utils/keyMap/unusual.js';


const UnusualList = () => {
  const userContext = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [pageNum, setPageNum] = useState(10);
  const [loading, setLoading] = useState(false);
  const [unusualLists, setUnusualLists] = useState([]);
  const [total, setTotal] = useState(0);
  const oriTotalRef = useRef(0);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const searchParams = useRef({});
  const [isSearching, setIsSearching] = useState(false);


  const getUnusualLists = useCallback(async () => {
    setLoading(true);
    try {
      let lists = await getUnusuals({ tenantId: userContext.tenantId, page, pageNum });
      if (lists === null) {
        setLoading(false);
        showErrorMessage();
        return;
      }
      lists.data = lists.data.map((item, index) => {
        return Object.assign({}, item, { key: index });
      });
      setShowCheckBox(true);
      setUnusualLists(lists.data);
      setTotal(lists.total);
      setLoading(false);
      oriTotalRef.current = lists.total;

    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('unusual-list - getUnusuals', e)
    }
  }, [userContext.tenantId, page, pageNum]);

  const showErrorMessage = () => {
    message.error('網路異常，請稍後重試。');
  }

  useEffect(() => {
    getUnusualLists();
  }, [getUnusualLists]);

  const columns = [
    {
      title: '工單號碼',
      dataIndex: 'workOrderId',
      width: 300
    },
    {
      title: '工單狀態',
      dataIndex: 'status',
      render: (text, record) => {
        return (
          unusualKeyMap[text] === undefined
            ?
            text.split("\n").map((str, index) => {
              return (
                index === text.split("\n").length - 1
                  ?
                  <span key={index}> {str}</span>
                  :
                  <div key="div">
                    <span key={index}>{str}</span>
                    <br />
                  </div>
              )
            })
            :
            <div>{unusualKeyMap[text]}</div>
        )
      }
    },
    {
      title: '紀錄時間',
      dataIndex: 'recordTime',
      render: (val) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    },
    {
      title: '解決時間',
      dataIndex: 'resolveTime',
      render: (val) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '';
      }
    }
  ];

  const rowSelection = { // 勾選的CheckBox
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };

  const handleChange = (page, pageSize) => {
    setPage(page);
    setPageNum(pageSize);
  }

  const handleUpload = async () => {
    setLoading(true);
    let datas = [];
    let newUnusualLists = Array.from(unusualLists);
    for (const item of selectedRows) {
      let data = {
        uuid: item.hasOwnProperty('uuid') ? item.uuid : item.woohUuids, // 允統 woohUuids / 有成、久可 uuid
        workOrderId: item.workOrderId,
        tenantId: item.tenantId
      }
      datas.push(data);
    }
    let result = await updateUnusualResolveTime(datas);
    if (result.length > 0) {
      for (const item of result) {
        let index = newUnusualLists.findIndex((element) => {
          return element.workOrderId === item.workOrderId;
        });
        let newData = Object.assign({}, newUnusualLists[index], { resolveTime: item.resolveTime })
        newUnusualLists.splice(index, 1, newData);
      }
      setUnusualLists(newUnusualLists);
      setLoading(false);
      setSelectedRows([]);
    }
  }

  const handleMultipleInputSearchOnChange = (e) => {
    let value = e.target.value.trim();
    if (value !== '') {
      searchParams.current = Object.assign({}, searchParams.current, { workOrderId: value });
    } else {
      if (searchParams.current.hasOwnProperty("workOrderId")) {
        delete searchParams.current["workOrderId"];
      }
    }
  }

  const handleMultipleDateSearchOnChange = (date, dateString, dateField) => {
    if (date !== null) {
      const timeStart = dateString
        ? dayjs(dateString[0]).startOf("day").toISOString()
        : undefined;
      const timeEnd = dateString
        ? dayjs(dateString[1]).endOf("day").toISOString()
        : undefined;
      switch (dateField) {
        case "recordTime":
          searchParams.current = Object.assign({}, searchParams.current, { recordTimeStart: timeStart, recordTimeEnd: timeEnd });
          break;
        case "resolveTime":
          searchParams.current = Object.assign({}, searchParams.current, { resolveTimeStart: timeStart, resolveTimeEnd: timeEnd });
          break;
        default:
          break;
      }
    } else {
      if (dateField === "recordTime" && searchParams.current.hasOwnProperty("recordTimeStart")) {
        delete searchParams.current["recordTimeStart"];
        delete searchParams.current["recordTimeEnd"];
      }

      if (dateField === "resolveTime" && searchParams.current.hasOwnProperty("resolveTimeStart")) {
        delete searchParams.current["resolveTimeStart"];
        delete searchParams.current["resolveTimeEnd"];
      }
    }
  }

  const handleMultipleSearchOnClick = async () => {
    try {
      if (Object.keys(searchParams.current).length > 0) {
        setLoading(true);
        setIsSearching(true);
        let paramsForSearch = Object.assign({}, searchParams.current, { tenantId: userContext.tenantId });
        let lists = await getUnusualsBySearch(paramsForSearch);
        if (lists === null) {
          setLoading(false);
          showErrorMessage();
          return;
        }
        lists.data = lists.data.map((item, index) => {
          return Object.assign({}, item, { key: index });
        });
        setUnusualLists(lists.data);
        setTotal(lists.total);
        setLoading(false);
        oriTotalRef.current = lists.total;
      } else {
        if (isSearching) {
          getUnusualLists();
          setIsSearching(false);
        }
      }
    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('history-list - handleMultipleSearchOnClick', e)
    }
  }

  return (
    <div>
      <Row gutter={[8, 8]} style={{ marginTop: '12px', marginBottom: '12px' }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{unusualKeyMap['workOrderId']}</div>
            <div style={{ width: '100%' }}>
              <Input
                allowClear
                bordered={false}
                onChange={handleMultipleInputSearchOnChange}
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{unusualKeyMap['recordTime']}</div>
            <div>
              <DayjsDatePicker.RangePicker
                size="large"
                bordered={false}
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                }}
                allowClear
                placeholder={['開始時間', '結束時間']}
                onChange={(date, dateString) =>
                  handleMultipleDateSearchOnChange(
                    date,
                    dateString,
                    "recordTime"
                  )
                }
                ranges={{
                  昨天: [
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day"),
                    dayjs()
                      .subtract(1, "day")
                      .endOf("day"),
                  ],
                  今天: [
                    dayjs().startOf("day"),
                    dayjs().endOf("day"),
                  ],
                  本週: [
                    dayjs().startOf("week"),
                    dayjs().endOf("week"),
                  ],
                  上週: [
                    dayjs()
                      .subtract(1, "week")
                      .startOf("week"),
                    dayjs()
                      .subtract(1, "week")
                      .endOf("week"),
                  ],
                  本月: [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  前一個月: [
                    dayjs()
                      .subtract(1, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前二個月: [
                    dayjs()
                      .subtract(2, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前三個月: [
                    dayjs()
                      .subtract(3, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{unusualKeyMap['resolveTime']}</div>
            <div>
              <DayjsDatePicker.RangePicker
                size="large"
                bordered={false}
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                }}
                allowClear
                placeholder={['開始時間', '結束時間']}
                onChange={(date, dateString) =>
                  handleMultipleDateSearchOnChange(
                    date,
                    dateString,
                    "resolveTime"
                  )
                }
                ranges={{
                  昨天: [
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day"),
                    dayjs()
                      .subtract(1, "day")
                      .endOf("day"),
                  ],
                  今天: [
                    dayjs().startOf("day"),
                    dayjs().endOf("day"),
                  ],
                  本週: [
                    dayjs().startOf("week"),
                    dayjs().endOf("week"),
                  ],
                  上週: [
                    dayjs()
                      .subtract(1, "week")
                      .startOf("week"),
                    dayjs()
                      .subtract(1, "week")
                      .endOf("week"),
                  ],
                  本月: [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  前一個月: [
                    dayjs()
                      .subtract(1, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前二個月: [
                    dayjs()
                      .subtract(2, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前三個月: [
                    dayjs()
                      .subtract(3, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={handleMultipleSearchOnClick}
            type="primary"
          >
            {unusualKeyMap['search']}
          </Button>
        </Col>
      </Row>

      <Divider />
      {showCheckBox && selectedRows.length > 0
        ?
        <Button type="primary" onClick={handleUpload}> 完成 </Button>
        :
        null
      }
      <Table
        loading={loading}
        columns={columns}
        dataSource={unusualLists}
        rowSelection={showCheckBox ? rowSelection : false}
        pagination={false}
        scroll={{ y: 600 }}
      />
      {/* { isSearching
          ?
            null
          : */}
      <Pagination
        style={{ marginTop: '16px', textAlign: 'right' }}
        total={total}
        current={page}
        pageSize={pageNum}
        showSizeChanger={true}
        pageSizeOptions={[10, 20, 50, 100]}
        showQuickJumper={true}
        showTotal={() => {
          // if( tableHasFilter.current ) {
          //   return `本頁篩選結果為 ${ total } 筆`
          // } else {
          return `共 ${total} 筆`
          // }
        }}
        onChange={handleChange}
      />
      {/* } */}
    </div>
  );
};
export default UnusualList;