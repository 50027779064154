import React, { useEffect, useState } from 'react';
import { Switch, Tag, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAllCronjobsStatus, getCronjobStatusByJobName, createCronjobByJobNameAndCronString, deleteCronjobByJobName } from '../../api/erp-utils-api.js';
import CronTabForm from './cron-tab-form.js'
import LastAutoSyncInfo from './last-auto-sync-info.js';

const OtherJobs = (props) => {
    const [initOK, setInitOK] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [jobStatusMap, setJobStatusMap] = useState(null);
    const [jobCronStringMap, setJobCronStringMap] = useState(null);
    const [jobReportIntervalMap, setJobReportIntervalMap] = useState(null);
    const [jobSwitchLoadingMap, setJobSwitchLoadingMap] = useState(null);
    const [jobStatusLoadingMap, setJobStatusLoadingMap] = useState(null);
    const [jobSupportReportIntervalMap, setJobSupportReportIntervalMap] = useState(null);
    const [jobCronjobIntervalRangeMap, setJobCronjobIntervalRangeMap] = useState(null);

    useEffect(() => {
        let tmpJobStatusLoadingMap = {};
        let tmpJobSwitchLoadingMap = {};
        for (const jobName of Object.keys(props.availableJobs)) {
            tmpJobStatusLoadingMap[jobName] = false;
            tmpJobSwitchLoadingMap[jobName] = false;

        }
        setJobStatusLoadingMap(tmpJobStatusLoadingMap);
        setJobSwitchLoadingMap(tmpJobSwitchLoadingMap);

        // get jobs status
        getAllCronjobsStatus().then((res) => {
            let tmpJobCronStringMap = {};
            let tmpJobReportIntervalMap = {};
            let tmpJobSupportReportIntervalMap = {};
            let tmpJobCronjobIntervalRangeMap = {};
            for (const [jobName, jobStatus] of Object.entries(res)) {
                tmpJobSupportReportIntervalMap[jobName] = (props.jobsProperties !== null && props.jobsProperties[jobName]["supportReportInterval"] === true);

                if (props.jobsProperties !== null && props.jobsProperties[jobName]["cronjobIntervalRange"] !== undefined) {
                    let range = props.jobsProperties[jobName]["cronjobIntervalRange"];
                    if (range.length === 2 && (range[1] - range[0]) > 0) {
                        tmpJobCronjobIntervalRangeMap[jobName] = props.jobsProperties[jobName]["cronjobIntervalRange"];
                    } else {
                        tmpJobCronjobIntervalRangeMap[jobName] = [20, 59];
                    }
                } else {
                    tmpJobCronjobIntervalRangeMap[jobName] = [20, 59];
                }

                if (jobStatus.status === true) {
                    tmpJobCronStringMap[jobName] = `0 0/${jobStatus.time} * * * *`;
                    tmpJobReportIntervalMap[jobName] = jobStatus.reportInterval;
                } else {
                    tmpJobCronStringMap[jobName] = `0 0/${tmpJobCronjobIntervalRangeMap[jobName][0]} * * * *`;
                    tmpJobReportIntervalMap[jobName] = tmpJobSupportReportIntervalMap[jobName] ? '60m' : null;
                }


            }

            setJobCronStringMap(tmpJobCronStringMap);
            setJobReportIntervalMap(tmpJobReportIntervalMap);
            setJobSupportReportIntervalMap(tmpJobSupportReportIntervalMap);
            setJobCronjobIntervalRangeMap(tmpJobCronjobIntervalRangeMap);
            setJobStatusMap(res);
            setHasError(false);
        }).catch((err) => {
            message.error("獲取自動同步狀態失敗，請稍後再試！");
            setHasError(true);
        }).then(() => {
            setInitOK(true);
        });
    }, [props.availableJobs, props.jobsProperties]);

    const getOneCronjobStatus = (jobName) => {
        let tmpJobStatusLoadingMap = Object.assign({}, jobStatusLoadingMap);
        tmpJobStatusLoadingMap[jobName] = true;
        setJobStatusLoadingMap(tmpJobStatusLoadingMap);

        getCronjobStatusByJobName(jobName).then((res) => {
            let tmpJobStatusMap = JSON.parse(JSON.stringify(jobStatusMap));
            tmpJobStatusMap[jobName] = res[jobName];
            setJobStatusMap(tmpJobStatusMap);
        }).catch((err) => {
            message.error(`獲取${props.availableJobs[jobName]}狀態失敗，請稍後再試！`);
        }).then(() => {
            let tmpJobStatusLoadingMap = Object.assign({}, jobStatusLoadingMap);
            tmpJobStatusLoadingMap[jobName] = false;
            setJobStatusLoadingMap(tmpJobStatusLoadingMap);
        });
    };

    const changeCronjobStatus = (jobName, status) => {
        let tmpJobStatusMap = JSON.parse(JSON.stringify(jobStatusMap));
        let tmpJobSwitchLoadingMap = Object.assign({}, jobSwitchLoadingMap);
        tmpJobSwitchLoadingMap[jobName] = true;
        setJobSwitchLoadingMap(tmpJobSwitchLoadingMap);

        switch (status) {
            case true:
                let reportInterval = jobReportIntervalMap[jobName];
                createCronjobByJobNameAndCronString(jobName, jobCronStringMap[jobName], reportInterval).then((res) => {
                    tmpJobStatusMap[jobName]["status"] = true;
                    setJobStatusMap(tmpJobStatusMap);
                    getOneCronjobStatus(jobName);
                }).catch((err) => {
                    message.error(`開啟${props.availableJobs[jobName]}失敗！`);
                }).then(() => {
                    let tmpJobSwitchLoadingMap = Object.assign({}, jobSwitchLoadingMap);
                    tmpJobSwitchLoadingMap[jobName] = false;
                    setJobSwitchLoadingMap(tmpJobSwitchLoadingMap);
                });
                break;
            case false:
                deleteCronjobByJobName(jobName).then((res) => {
                    tmpJobStatusMap[jobName]["status"] = true;
                    setJobStatusMap(tmpJobStatusMap);
                    getOneCronjobStatus(jobName);
                }).catch((err) => {
                    message.error(`關閉${props.availableJobs[jobName]}失敗！`);
                }).then(() => {
                    let tmpJobSwitchLoadingMap = Object.assign({}, jobSwitchLoadingMap);
                    tmpJobSwitchLoadingMap[jobName] = false;
                    setJobSwitchLoadingMap(tmpJobSwitchLoadingMap);
                });
                break;
            default:
                break;
        }
    };

    const jobComponent = (title, jobName) => {
        let lastSyncStatus = null;
        let lastSyncTime = null;

        if (jobStatusMap[jobName]["status"] === true && (jobStatusMap[jobName]["lastSync"] !== undefined && jobStatusMap[jobName]["lastSync"] !== null)) {
            lastSyncStatus = jobStatusMap[jobName]["lastSync"]["status"];
            lastSyncTime = jobStatusMap[jobName]["lastSync"]["time"];
        }

        return (
            <div key={jobName} style={{ height: '80px', marginBottom: '24px' }}>
                <div style={{ display: 'flex', marginBottom: '12px' }}>
                    <h3>
                        {title}
                    </h3>
                    <Switch
                        checkedChildren="開啟"
                        unCheckedChildren="關閉"
                        checked={jobStatusMap[jobName]["status"]}
                        disabled={false}
                        onClick={(checked) => {
                            changeCronjobStatus(jobName, checked);
                        }}
                        style={{ marginLeft: '12px' }}
                        loading={jobSwitchLoadingMap[jobName]}
                    />
                </div>
                <div>
                    {
                        !jobStatusLoadingMap[jobName] && jobStatusMap[jobName]["status"]
                            ?
                            <div>
                                <LastAutoSyncInfo
                                    loading={jobStatusLoadingMap[jobName]}
                                    lastSyncStatus={lastSyncStatus}
                                    lastSyncTime={lastSyncTime}
                                    nextDate={jobStatusMap[jobName]["nextDate"]}
                                />
                                {jobReportIntervalMap !== null && jobReportIntervalMap[jobName] !== undefined && jobReportIntervalMap[jobName] !== null
                                    ?
                                    <div style={{ marginTop: '12px' }}>
                                        回報間隔： <Tag> {jobReportIntervalMap[jobName].split("m")[0]} 分鐘 </Tag>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            <div>
                                <CronTabForm
                                    cronjobIntervalRange={jobCronjobIntervalRangeMap[jobName]}
                                    supportReportInterval={jobSupportReportIntervalMap[jobName]}
                                    cronString={jobCronStringMap[jobName]}
                                    setCronString={(newCronString) => {
                                        let tmpJobCronStringMap = Object.assign({}, jobCronStringMap);
                                        tmpJobCronStringMap[jobName] = newCronString;
                                        setJobCronStringMap(tmpJobCronStringMap);
                                    }}
                                    reportInterval={jobReportIntervalMap[jobName]}
                                    setReportInterval={(newReportInterval) => {
                                        let tmpJobReportIntervalMap = Object.assign({}, jobReportIntervalMap);
                                        tmpJobReportIntervalMap[jobName] = newReportInterval;
                                        setJobReportIntervalMap(tmpJobReportIntervalMap);
                                    }}
                                />
                            </div>
                    }
                </div>
            </div>
        );
    };

    return (
        initOK
            ?
            !hasError
                ?
                <div>
                    {Object.entries(props.availableJobs).map(([jobName, title]) => {
                        return jobComponent(title, jobName);
                    })}
                </div>
                :
                <div>獲取狀態失敗，請稍後再試。</div>
            :
            <LoadingOutlined />
    );
};

export default OtherJobs;
