import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { Table, Tag, Pagination, message, Col, Row, Input, Button, Select } from 'antd';
import dayjs from 'dayjs';
import DayjsDatePicker from '../common/dayjs-date-picker';
import { getHistorys, getHistorysBySearch } from '../../api/request';
import { UserContext } from '../../App.js';
import { historyKeyMap } from '../../utils/keyMap/history.js';
import './history-list.css';
const HistoryList = () => {
  const userContext = useContext(UserContext);
  const [historyLists, setHistoryLists] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageNum, setPageNum] = useState(10);
  const oriTotalRef = useRef(0);
  const tableHasFilter = useRef(false);
  const [loading, setLoading] = useState(false);
  const searchParams = useRef({});
  const [isSearching, setIsSearching] = useState(false);

  const getHistoryLists = useCallback(async () => {
    setLoading(true);
    try {
      let lists = await getHistorys({ tenantId: userContext.tenantId, page, pageNum });
      if (lists === null) {
        setLoading(false);
        showErrorMessage();
        return;
      }
      lists.data = lists.data.map((item, index) => {
        return Object.assign({}, item, { key: index });
      });
      setHistoryLists(lists.data);
      setTotal(lists.total);
      setLoading(false);
      oriTotalRef.current = lists.total;
    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('history-list - getHistorys', e)
    }
  }, [userContext.tenantId, page, pageNum]);

  useEffect(() => {
    getHistoryLists();
  }, [getHistoryLists]);

  const showErrorMessage = () => {
    message.error('網路異常，請稍後重試。');
  }

  let columns = [
    {
      title: '工單號碼',
      dataIndex: 'workOrderId',
      width: 300
    },
    {
      title: '同步時間',
      dataIndex: 'syncTime',
      width: '200px',
      sorter: (a, b) => {
        if (a.syncTime > b.syncTime) {
          return 1;
        } else {
          return -1;
        }
      },
      sortDirections: ['descend', 'ascend'],
      render: (val) => {
        return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      title: "工單同步",
      width: '200px',
      children: [
        {
          title: '狀態',
          dataIndex: 'workOrderSyncStatus',
          width: '80px',
          filters: [
            {
              text: '成功',
              value: true
            },
            {
              text: '失敗',
              value: false
            }
          ],
          onFilter: (value, record) => {
            if (value === true) {
              return record.workOrderSyncStatus === true;
            } else {
              return record.workOrderSyncStatus === false;
            }
          },
          render: (val) => {
            let status = '';
            let color = '';
            switch (val) {
              case true:
                status = '成功';
                color = '#87d068';
                break;
              case false:
                status = '失敗';
                color = '#f50';
                break;
              case null:
                status = '-'
                break;
              default:
                break;
            }
            return val === null ? status : <Tag color={color}> {status} </Tag>;
          }
        },
        {
          title: '類型',
          width: '120px',
          dataIndex: 'workOrderSyncType',
          filters: [
            {
              text: '同步到SFR',
              value: 'create'
            },
            {
              text: '更新至SFR',
              value: 'patch'
            },
            {
              text: '同步至ERP',
              value: 'syncToCus'
            },
            {
              text: '刪除工單',
              value: 'delete'
            }
          ],
          onFilter: (value, record) => {
            if (value === 'create') {
              return record.workOrderSyncType === 'create';
            } else if (value === 'patch') {
              return record.workOrderSyncType === 'patch';
            } else if (value === 'syncToCus') {
              return record.workOrderSyncType === 'syncToCus';
            } else {
              return record.workOrderSyncType === 'delete';
            }
          },
          render: (val) => {
            let text = '';
            switch (val) {
              case 'create':
                text = '同步到SFR'
                break;
              case 'patch':
                text = '更新至SFR'
                break;
              case 'syncToCus':
                text = '同步至ERP'
                break;
              case 'delete':
                text = '刪除工單'
                break;
              case null:
                text = ''
                break;
              default:
                break;
            }
            return text;
          }
        }
      ]
    },
    {
      title: "異常工時同步",
      width: '200px',
      children: [
        {
          title: '狀態',
          dataIndex: 'whahSyncStatus',
          width: '80px',
          filters: [
            {
              text: '成功',
              value: true
            },
            {
              text: '失敗',
              value: false
            }
          ],
          onFilter: (value, record) => {
            if (value === true) {
              return record.whahSyncStatus === true;
            } else {
              return record.whahSyncStatus === false;
            }
          },
          render: (val) => {
            let status = '';
            let color = '';
            switch (val) {
              case true:
                status = '成功';
                color = '#87d068';
                break;
              case false:
                status = '失敗';
                color = '#f50';
                break;
              case null:
                status = '-'
                break;
              default:
                break;
            }
            return val === null ? status : <Tag color={color}> {status} </Tag>;
          }
        },
        {
          title: '類型',
          dataIndex: 'whahSyncType',
          width: '120px',
          filters: [
            {
              text: '同步到SFR',
              value: 'toSfr'
            },
            {
              text: '同步至ERP',
              value: 'toCus'
            },
            {
              text: '刪除異常工時',
              value: 'delete'
            }
          ],
          onFilter: (value, record) => {
            if (value === 'toSfr') {
              return record.whahSyncType === 'toSfr';
            } else if (value === 'toCus') {
              return record.whahSyncType === 'toCus';
            } else {
              return record.whahSyncType === 'delete';
            }
          },
          render: (val) => {
            let text = '';
            switch (val) {
              case 'toSfr':
                text = '同步至SFR'
                break;
              case 'toCus':
                text = '同步至ERP'
                break;
              case 'delete':
                text = '刪除異常工時'
                break;
              case null:
                text = '-'
                break;
              default:
                break;
            }
            return text;
          }
        }
      ]
    },
    {
      title: '手動/自動',
      width: '100px',
      dataIndex: 'syncType',
      render: (val) => {
        let color = '';
        let text = '';
        switch (val) {
          case 'manual':
            text = '手動';
            color = 'magenta';
            break;
          case 'autoSync':
            text = '自動';
            color = 'geekblue'
            break;
          default:
            break;
        }
        return <Tag color={color}>{text}</Tag>;
      }
    }
  ]

  const handleChange = (page, pageSize) => {
    setPage(page);
    setPageNum(pageSize);
  }

  const filterAndSort = (pagination, filters, sorter) => {
    if (Object.values(filters).find((item) => item !== null)) {
      let filterLists = historyLists;
      for (const [keyName, value] of Object.entries(filters)) {
        if (value !== null) {
          filterLists = filterLists.filter((item) => {
            return value.find((filterValue) => {
              return filterValue === item[keyName];
            }) !== undefined ? true : false;
          });

        }
      }
      tableHasFilter.current = true;
      setTotal(filterLists.length);
    } else {
      tableHasFilter.current = false;
      setTotal(oriTotalRef.current);
    }
  }

  const handleMultipleInputSearchOnChange = (e) => {
    let value = e.target.value.trim();
    if (value !== '') {
      searchParams.current = Object.assign({}, searchParams.current, { workOrderId: value });
    } else {
      if (searchParams.current.hasOwnProperty("workOrderId")) {
        delete searchParams.current["workOrderId"];
      }
    }
  }
  const handleMultipleDateSearchOnChange = (date, dateString) => {
    if (date !== null) {
      const timeStart = dateString
        ? dayjs(dateString[0]).startOf("day").toISOString()
        : undefined;
      const timeEnd = dateString
        ? dayjs(dateString[1]).endOf("day").toISOString()
        : undefined;
      searchParams.current = Object.assign({}, searchParams.current, { syncTimeStart: timeStart, syncTimeEnd: timeEnd });
    } else {
      if (searchParams.current.hasOwnProperty("syncTimeStart")) {
        delete searchParams.current["syncTimeStart"];
        delete searchParams.current["syncTimeEnd"];
      }
    }
  }

  const handleMultipleStatusSearchOnClick = (e) => {
    if (e !== undefined) {
      searchParams.current = Object.assign({}, searchParams.current, { workOrderSyncStatus: e.value });
    } else {
      delete searchParams.current["workOrderSyncStatus"];
    }
  }

  const handleMultipleSearchOnClick = async () => {
    try {
      if (Object.keys(searchParams.current).length > 0) {
        setLoading(true);
        setIsSearching(true);
        let paramsForSearch = Object.assign({}, searchParams.current, { tenantId: userContext.tenantId });
        let lists = await getHistorysBySearch(paramsForSearch);
        if (lists === null) {
          setLoading(false);
          showErrorMessage();
          return;
        }
        lists.data = lists.data.map((item, index) => {
          return Object.assign({}, item, { key: index });
        });
        setHistoryLists(lists.data);
        setTotal(lists.total);
        setLoading(false);
        oriTotalRef.current = lists.total;
      } else {
        if (isSearching) {
          getHistoryLists();
          setIsSearching(false);
        }
      }
    } catch (e) {
      setLoading(false);
      showErrorMessage();
      console.log('history-list - handleMultipleSearchOnClick', e)
    }
  }

  return (
    <div>
      <Row gutter={[8, 0]}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{historyKeyMap['workOrderId']}</div>
            <div style={{ width: '100%' }}>
              <Input
                allowClear
                bordered={false}
                onChange={handleMultipleInputSearchOnChange}
                style={{ height: '100%', width: '100%' }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={8}
          xxl={6}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '80px' }}>{historyKeyMap['syncTime']}</div>
            <div>
              <DayjsDatePicker.RangePicker
                size="large"
                bordered={false}
                style={{
                  textAlign: "center",
                  flexGrow: 1,
                }}
                allowClear
                placeholder={['開始時間', '結束時間']}
                onChange={handleMultipleDateSearchOnChange}
                ranges={{
                  昨天: [
                    dayjs()
                      .subtract(1, "day")
                      .startOf("day"),
                    dayjs()
                      .subtract(1, "day")
                      .endOf("day"),
                  ],
                  今天: [
                    dayjs().startOf("day"),
                    dayjs().endOf("day"),
                  ],
                  本週: [
                    dayjs().startOf("week"),
                    dayjs().endOf("week"),
                  ],
                  上週: [
                    dayjs()
                      .subtract(1, "week")
                      .startOf("week"),
                    dayjs()
                      .subtract(1, "week")
                      .endOf("week"),
                  ],
                  本月: [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  前一個月: [
                    dayjs()
                      .subtract(1, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前二個月: [
                    dayjs()
                      .subtract(2, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                  前三個月: [
                    dayjs()
                      .subtract(3, "month")
                      .startOf("month"),
                    dayjs()
                      .subtract(1, "month")
                      .endOf("month"),
                  ],
                }}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={6}
          xxl={6}
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1
          }}
        >
          <div className='searchBarParent'>
            <div className='searchBarItemTitle' style={{ minWidth: '120px' }}>{historyKeyMap['workOrderSyncStatus']}</div>
            <div style={{ width: '100%' }}>
              <Select
                labelInValue
                allowClear
                bordered={false}
                style={{ width: '100%' }}
                onChange={handleMultipleStatusSearchOnClick}
                options={[
                  {
                    value: 'true',
                    label: historyKeyMap['success']
                  },
                  {
                    value: 'false',
                    label: historyKeyMap['fail']
                  },
                ]}
              />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={4}
          xxl={4}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Button
            onClick={handleMultipleSearchOnClick}
            type="primary"
            size="large"
          >
            {historyKeyMap['search']}
          </Button>
        </Col>
      </Row>

      <Table
        style={{ minWidth: '700px', marginTop: '24px' }}
        columns={columns}
        dataSource={historyLists}
        onChange={filterAndSort}
        pagination={!isSearching ? false : {
          pageSizeOptions: [10, 20, 50, 100],
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: () => {
            if (tableHasFilter.current) {
              return `本頁篩選結果為 ${total} 筆`
            } else {
              return `共 ${total} 筆`
            }
          }
        }}
        loading={loading}
      />
      {isSearching
        ?
        null
        :
        <Pagination
          style={{ marginTop: '16px', textAlign: 'right' }}
          total={total}
          current={page}
          pageSize={pageNum}
          showSizeChanger={true}
          pageSizeOptions={[10, 20, 50, 100]}
          showQuickJumper={true}
          showTotal={() => {
            if (tableHasFilter.current) {
              return `本頁篩選結果為 ${total} 筆`
            } else {
              return `共 ${total} 筆`
            }
          }}
          onChange={handleChange}
        />
      }
    </div>
  );
}
export default HistoryList;