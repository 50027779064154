import React, { useState, useRef } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

const CronTabForm = (props) => {
  const [form] = Form.useForm();
  const [initOK, setInitOK] = useState(false);
  const cronjobIntervalRange = useRef([20, 59]);

  useEffect(() => {
    let string = props.cronString.split(" ");
    let newCronjobInterval = parseInt(string[1].split("/")['1']);
    let newReportInterval = (props.reportInterval === undefined || props.reportInterval === null) ? '60' : props.reportInterval.split("m")[0];

    if (props.cronjobIntervalRange !== undefined && props.cronjobIntervalRange !== null) {
      if (props.cronjobIntervalRange.length === 2 && (props.cronjobIntervalRange[1] - props.cronjobIntervalRange[0]) > 0) {
        cronjobIntervalRange.current[0] = props.cronjobIntervalRange[0];
        cronjobIntervalRange.current[1] = props.cronjobIntervalRange[1];
      }
    }

    form.setFieldsValue({ cronjobInterval: newCronjobInterval });

    if (props.supportReportInterval) {
      form.setFieldsValue({ reportInterval: newReportInterval });
    }

    setInitOK(true);
  }, [props.cronjobIntervalRange, props.supportReportInterval, props.cronString, props.reportInterval, form]);

  const generateMinuteArray = () => {
    let start = cronjobIntervalRange.current[0];
    let end = cronjobIntervalRange.current[1];

    return Array.from({ length: end - start + 1 }, (_, i) => {
      return { label: `${start + i}`, value: start + i };
    });
  };

  return (
    initOK
      ?
      <Form
        form={form}
        colon={false}
        layout='horizontal'
        onValuesChange={(event, values) => {
          if (props.supportReportInterval) {
            props.setReportInterval(`${values['reportInterval']}m`);
          }
          props.setCronString(`0 0/${values['cronjobInterval']} * * * *`)
        }}
      >
        <Form.Item
          name="cronjobInterval"
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
                <div> 間隔分鐘 </div>
                <Tooltip placement="right" title="除了間隔分鐘外，整點時也會同步一次">
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
              <div>：</div>
            </div>
          }
        >
          <Select
            size="middle"
            options={generateMinuteArray()}
            style={{ width: '230px' }}
          />
        </Form.Item>
        {props.supportReportInterval
          ?
          <Form.Item
            name="reportInterval"
            label="回報間隔分鐘："
          >
            <Select
              size="middle"
              options={[{ label: 30, value: 30 }, { label: 60, value: 60 }]}
              style={{ width: '230px' }}
            />
          </Form.Item>
          :
          null
        }
      </Form>
      :
      null
  );
};

export default CronTabForm;

