import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LastAutoSyncInfo = (props) => {
    return (
        <div style={{ display: 'flex', gap: '24px', width: '100%' }}>
            <div>
                上次執行時間：
                {!props.loading
                    ?
                    props.lastSyncTime !== null
                        ?
                        <span>
                            {(new Date(props.lastSyncTime)).toLocaleString('zh-tw')}
                            {props.lastSyncStatus === null
                                ? null
                                : props.lastSyncStatus === true
                                    ? <span style={{ fontWeight: 'bold', color: "#87d068" }}>（執行成功）</span>
                                    : <span style={{ fontWeight: 'bold', color: "red" }}>（客戶端連接失敗） </span>
                            }
                        </span>
                        :
                        <span> 無 </span>
                    : <LoadingOutlined style={{ marginLeft: '8px' }} />
                }
            </div>
            <span>
                下次執行時間：
                {!props.loading && props.nextDate
                    ? (new Date(props.nextDate)).toLocaleString('zh-tw')
                    : <LoadingOutlined style={{ marginLeft: '8px' }} />
                }
            </span>
        </div>
    );
};

export default LastAutoSyncInfo;