import React from 'react';
import { Steps } from 'antd';
// import { workOrderStatus } from './order-column-keymap';

const OrderLocation = (props) => {
  console.log('props.', props.displayStatus)
  const xStep = { title: '無需同步' };
  const steps = [
    { title: '未同步到SFR' },
    { title: '已同步到SFR' },
    { title: '未同步至ERP' },
    { title: '已同步至ERP' },
  ];

  const displayStatus2Current = {
    X: 0,
    N: 1,
    R: 1,
    needUpdateWoohToSfr: 1,
    Y: 2,
    needSyncERP: 3,
    alreadySyncERP: 4
  };

  return (
    <Steps
      size="small"
      current={ props.reason === null ? displayStatus2Current[props.displayStatus] : 2 }
      status={ props.displayStatus === "X" ? "error" : null }
      items={ props.displayStatus === "X" && props.reason === null ? [ xStep, ...steps ] : steps }
    />
  );
};

export default OrderLocation;