import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Switch, Divider, message } from 'antd';
import { UserContext } from '../../App.js';
import { listJobs, deleteJob, createJob, updateSfrToErpStatus } from '../../api/request.js';
import CronTabForm from './cron-tab-form.js'
import HistoryList from './history-list.js';
import LastAutoSyncInfo from './last-auto-sync-info.js';

const WorkOrderJob = () => {
  const userContext = useContext(UserContext);
  const [orderJob, setOrderJob] = useState(false);
  const [cronOrder, setCronOrder] = useState('0 0/20 * * * *');
  const [orderSwitchDisabled, setOrderSwitchDisabled] = useState(true);
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [lastSyncStatus, setLastSyncStatus] = useState(null);
  const [erpToSfrSwitchLoading, setErpToSfrSwitchLoading] = useState(false);
  const [sfrToErpSwitchLoading, setSfrToErpSwitchLoading] = useState(false);
  const [jobStatusLoading, setJobStatusLoading] = useState(false);

  const setErpToSfrStatus = async (params) => {
    setErpToSfrSwitchLoading(true);
    const { action } = params;

    let result;
    if (action === 'delete') {
      result = await deleteJob(params, userContext.tenantId);
    } else if (action === 'create') {
      result = await createJob(params, userContext.tenantId);
    }

    setErpToSfrSwitchLoading(false);
    if (result.status.toString() === '200') {
      let tmpOrderJob = Object.assign({}, orderJob);
      setOrderJob(Object.assign(tmpOrderJob, { status: !tmpOrderJob.status }));
      message.success("設定成功！");
    } else {
      message.error("設定失敗，請稍後再試。");
    }

    setJobStatusLoading(true);
    await getJobStatus();
  };

  const setSfrToErpStatus = async (params) => {
    setSfrToErpSwitchLoading(true);

    const { action } = params;
    let result = await updateSfrToErpStatus(params, userContext.tenantId);

    let tmpOrderJob = Object.assign({}, orderJob);
    tmpOrderJob["sfrToErp"] = action === 'create' ? true : false;

    if (result.status.toString() === '200') {
      setOrderJob(tmpOrderJob);
      message.success("設定成功！");
    } else {
      message.error("設定失敗，請稍後再試。");
    }

    setSfrToErpSwitchLoading(false);
  };

  const getJobStatus = useCallback(async () => {
    let jobs = await listJobs({ tenantId: userContext.tenantId });
    if (jobs.success) {
      let { orderJob, lastAutoSyncInfo } = jobs.data;
      if (!orderJob.hasOwnProperty('nextDate')) {
        orderJob = Object.assign({}, orderJob, { nextDate: null, time: null });
      } else {
        setCronOrder(`0 0/${orderJob.time} * * * *`);
        if (lastAutoSyncInfo !== null) {
          setLastSyncTime(lastAutoSyncInfo.order.lastSync.time);
          setLastSyncStatus(lastAutoSyncInfo.order.lastSync.status);
        }
      }
      setOrderJob(orderJob);
    }

    setOrderSwitchDisabled(jobs.data.orderJob.switchDisabled);
    setJobStatusLoading(false);
  }, [userContext.tenantId]);

  useEffect(() => {
    getJobStatus();
  }, [getJobStatus]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}>
        <h3>
          ERP製令單 -> DZ Connect 工單同步
        </h3>
        <Switch
          checkedChildren="開啟"
          unCheckedChildren="關閉"
          checked={orderJob.status}
          disabled={orderSwitchDisabled} // 不在erp_customer_info的就是true 無法點選開關
          onClick={(checked) => {
            setErpToSfrStatus({
              action: checked ? 'create' : 'delete',
              type: 'order',
              cronString: cronOrder,
              syncType: 'erpToSfr'
            });
          }}
          style={{ marginLeft: '12px' }}
          loading={erpToSfrSwitchLoading}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}>
        <h3>
          DZ Connect 工單同步 -> ERP製令單
        </h3>
        <Switch
          checkedChildren="開啟"
          unCheckedChildren="關閉"
          checked={orderJob.sfrToErp}
          disabled={orderSwitchDisabled} // 不在erp_customer_info的就是true 無法點選開關
          onClick={(checked) => {
            setSfrToErpStatus({
              action: checked ? 'create' : 'delete',
              type: 'order',
              syncType: 'sfrToErp'
            });
          }}
          style={{ marginLeft: '12px' }}
          loading={sfrToErpSwitchLoading}
        />
      </div>
      {
        !orderJob.status
          ?
          <CronTabForm
            supportReportInterval={false}
            cronString={cronOrder}
            setCronString={setCronOrder}
          />
          :
          <LastAutoSyncInfo
            loading={jobStatusLoading}
            lastSyncStatus={lastSyncStatus}
            lastSyncTime={lastSyncTime}
            nextDate={orderJob.nextDate}
          />
      }
      <Divider />
      <HistoryList />
    </div>
  );
};

export default WorkOrderJob;