import React from 'react';
import { Modal, Badge, List } from 'antd';

const FailedList = (props) => {
  const { failedListVisible, setFailedListVisible, uploadResultList } = props;

  const displayUploadResult = () => {
    let style = uploadResultList.failed.length === 0 || uploadResultList.success.length === 0 ? 
      { width: '100%', height: '100%' } :
      { display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%', height: '100%' };

    return (
      <div style={ style }>
        { uploadResultList.success.length > 0 ?
          displayListItem('success') :
          null
        }

        { uploadResultList.failed.length > 0 ?
          displayListItem('failed') :
          null
        }
      </div>
    )
  }

  const displayListItem = (listStatus) => {
    return (
      <List
        key={listStatus}
        size="small"
        header={ <Badge status={listStatus === 'failed' ? 'error' : 'success'} text={listStatus === 'failed' ? '上傳失敗' : '上傳成功'} />}
        bordered={ true }
        dataSource={ uploadResultList[listStatus] }
        pagination={{ showQuickJumper: true, pageSize: 10, showSizeChanger: false }}
        renderItem={ (item) => {
          return (
            <List.Item style={{ display: 'grid', gridTemplateRows: '50% 50%' }}>
              <div>
                <span style={{ marginRight: '8px' }}> { item } </span>
              </div>
            </List.Item>
          );
        }}
      />
    );
  }

  return (
    failedListVisible
    ?
      <Modal
        width='70%'
        destroyOnClose
        title="上傳結果"
        visible={failedListVisible}
        onCancel={() => setFailedListVisible(false)}
        footer={null}
      >
        { displayUploadResult() }
      </Modal>
    :
      null
  );
};

export default FailedList;
