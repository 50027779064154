import React, { useEffect, useState, useContext, useRef } from 'react';
import { ConfigProvider, Layout, Row, Col, Breadcrumb, Menu, Modal } from 'antd';
import zhTW from 'antd/es/locale/zh_TW.js';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import { MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined, ContainerOutlined } from '@ant-design/icons';
import { FileOutlined, SketchOutlined, SyncOutlined, LogoutOutlined } from '@ant-design/icons';

import OrderList from './order/order-list.js';
import ProductList from './product/product-list.js';
import JobList from './job/job-list.js';
import UnusualList from './unusual/unusual-list.js';
// import Setting from './setting/setting';

import { UserContext } from '../App.js';
import { Popover, Button } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import './app-switcher.css';

import { DZRelease } from '@dotzerotech/release-frontend';
import dayjs from 'dayjs';

const { Sider, Content, Footer, Header } = Layout;
const { confirm } = Modal;

const ErpLayout = () => {
  const userContext = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768 ? true : false);
  const [tenantTitle, setTenantTitle] = useState('');
  const history = useHistory();
  const [switcherZIndex, setSwitcherZIndex] = useState(-1);
  const releaseProject = useRef([{ projectID: "123", projectName: "ERP同步系統(前端)", order: 0 }, { projectID: "145", projectName: "ERP同步系統(後端)", order: 1 }]);

  useEffect(() => {
    const token = Cookies.get("token");
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_USER_API}/customer/info`,
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      setTenantTitle(res.data.title);
    }).catch((err) => {
      console.log("get customer title error:", err);
    });
  }, []);

  const getBreadcrumb = () => {
    let obj = [];
    obj.push(
      <Breadcrumb.Item key="home" onClick={() => { history.push("/"); }} style={{ cursor: 'pointer' }} className="layout-header-breadcrumb-home">
        <HomeOutlined style={{ marginRight: '8px' }} />
        {tenantTitle}
      </Breadcrumb.Item>
    );

    const pathStr = window.location.pathname;
    const path = pathStr.split("/");

    const pathMap = {
      'list-orders': '製令單匯入',
      'list-products': '產品匯入',
      'list-jobs': '自動同步',
      'list-unusual': '特殊狀態工單'
    };

    obj.push(<Breadcrumb.Item key={path[1]}> {pathMap[path[1]]} </Breadcrumb.Item>);

    return (
      <Breadcrumb className="layout-header-breadcrumb">
        {obj}
      </Breadcrumb>
    );
  };

  const showConfirm = () => {
    confirm({
      title: '確定登出？',
      icon: <LogoutOutlined />,
      okText: '確認',
      cancelText: '取消',
      onOk() {
        onSignOut();
      },
      style: { top: '30%' }
    });
  };

  const onSignOut = () => {
    let logoutUrl = process.env.REACT_APP_LOGIN_URL + 'logout?tenantId=' + userContext.tenantId + '&redirectUri=' + window.location.origin;
    window.location.replace(logoutUrl);
  };
  return (
    <ConfigProvider locale={zhTW}>
      <Layout
        onClick={() => {
          if (switcherZIndex !== -1) {
            setSwitcherZIndex(-1);
          }
        }}
      >
        { /* Custom Sider */}
        <Sider
          className="layout-sider"
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          breakpoint="xl"
          onBreakpoint={(breakpoint) => { setCollapsed(collapsed); }}
          style={{ height: '100vh', position: 'fixed', left: 0 }}
        >
          { /* ----------------- LOGO ----------------- */}
          <div className="sider-header"
            onClick={() => {
              history.push("/");
            }}
          >
            <Row gutter={[0, 0]} style={{ margin: '10px 0px' }}>
              <Col span={collapsed ? window.innerWidth > 1200 ? 24 : 0 : 8}>
                <div style={{ display: 'grid' }}>
                  <img src='/erp.png' alt="logo" style={{ margin: 'auto' }} />
                </div>
              </Col>
              <Col span={collapsed ? 0 : 16} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div> ERP 同步系統 </div>
                <div style={{ fontSize: '10px' }}> ERP Tool </div>
              </Col>
            </Row>
          </div>

          { /* ----------------- MENU ----------------- */}
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: 'calc(100vh - 50px)' }}>
            <Menu
              className="sider-menu"
              theme="dark"
              mode="inline"
              selectedKeys={[window.location.pathname]}
              onClick={(event) => { history.push(event.key); }}
              items={[
                {
                  key: "/list-orders",
                  icon: <FileOutlined />,
                  label: "製令單匯入"
                },
                {
                  key: "/list-products",
                  icon: <SketchOutlined />,
                  label: "產品匯入"
                },
                {
                  key: "/list-jobs",
                  icon: <SyncOutlined />,
                  label: "自動同步"
                },
                {
                  key: "/list-unusual",
                  icon: <ContainerOutlined />,
                  label: "特殊狀態工單"
                },
                /*
                {
                  key: "/setting",
                  icon: <SyncOutlined />,
                  label: "設定"
                }
                  */
              ]}
            />
            <Menu
              mode="inline"
              theme="dark"
              style={{ marginBottom: '20px' }}
              onClick={(event) => {
                if (event.key === '/logout') {
                  showConfirm();
                }
              }}
              items={[
                {
                  key: "/logout",
                  icon: <LogoutOutlined />,
                  label: "系統登出"
                }
              ]}
            />
          </div>
        </Sider>


        <Layout style={{ marginLeft: collapsed ? window.innerWidth > 1200 ? '80px' : '0px' : '200px', zIndex: 999 }}>
          {/* Custom Header */}
          <Header className="layout-header">
            <div className="layout-header-content">
              {
                collapsed ?
                  <MenuUnfoldOutlined className="collapse-trigger" onClick={() => { setCollapsed(!collapsed); }} /> :
                  <MenuFoldOutlined className="collapse-trigger" onClick={() => { setCollapsed(!collapsed); }} />
              }
              {getBreadcrumb()}
            </div>

            {/* Release */}
            <div className="layout-header-app-release">
              <div className="app-release-btn-container">
                <div className="app-release-btn">
                  <DZRelease
                    projects={releaseProject.current}
                  />
                </div>
              </div>
            </div>

            {/* app switcher */}
            <div className="layout-header-app-switcher">
              <Popover
                title={null}
                placement="bottom"
                open={true}
                zIndex={switcherZIndex}
                overlayClassName="app-switcher-popover"
                overlayInnerStyle={{ borderRadius: "15px" }}
                content={
                  <div className='mask'>
                    <iframe
                      src={process.env.REACT_APP_SWITCHER_URL}
                      title="app-switcher"
                      frameBorder="0"
                      className="app-switcher"
                    />
                  </div>
                }
              >
                <Button
                  className="layout-header-app-switcher-btn"
                  type="text"
                  shape="circle"
                  icon={<AppstoreOutlined color="black" />}
                  onClick={() => { setSwitcherZIndex(switcherZIndex === -1 ? 999 : -1); }}
                />
              </Popover>
            </div>
          </Header>

          {/* Custom Content */}
          <Content className="layout-content">
            <Switch>
              <Route exact path="/list-orders" component={OrderList} />
              <Route exact path="/list-products" component={ProductList} />
              <Route exact path="/list-jobs" component={JobList} />
              <Route exact path="/list-unusual" component={UnusualList} />
              {/* <Route exact patch="/Setting" component={ Setting } /> */}
              <Redirect path="/*" to="/list-orders" />
            </Switch>
          </Content>

          {/* Custom Footer */}
          <Footer className="layout-footer"> DotZero {process.env.REACT_APP_ERP_VERSION} ©{dayjs().year()} </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default ErpLayout;
