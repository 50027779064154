import axios from 'axios';
import Cookies from 'js-cookie';

let work_order_api;

export const init_work_order_api_request = () => {
  // basic axios instance
  work_order_api = axios.create({
    baseURL: process.env.REACT_APP_WORK_ORDER_API
  });

  // set interceptors for request
  work_order_api.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // set interceptors for response
  work_order_api.interceptors.response.use((request) => {
    return request;
  }, (error) => {
    if (error.response.status.toString() === '400' || error.response.status.toString() === '401') {
      if (sessionStorage.getItem('reloadUrl') !== undefined) {
        let reloadUrl = process.env.REACT_APP_LOGIN_URL + sessionStorage.getItem('reloadUrl') + window.location.href;
        window.location.replace(reloadUrl);
      }
    }
    return Promise.reject(error);
  });
};

// ----------------- PRODUCTS -----------------
export const getProductsFromDZ = (data) => {
  return work_order_api.get('/products/', {
    params: data
  }).then((res) => {
    return res.data;
  });
};

export const createProduct = (data) => {
  return work_order_api.post('/products/', data).then((res) => {
    return res.data;
  });
};
