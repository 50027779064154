import React, { useState, useRef, useContext } from 'react';
import ProTable from '@ant-design/pro-table';
import { Button, Modal, Badge, message } from 'antd';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { orderColumnKeyMap, reasonKeyMap } from './order-column-keymap.js';

import { uploadWorkOrderToDZ, getOrders } from '../../api/request.js';

import FailedList from './failed-list.js';

import { UserContext } from '../../App.js';
import OrderLocation from './order-location.js';
import { QuestionCircleOutlined } from '@ant-design/icons';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.tz.setDefault("Asia/Taipei");

message.config({
  duration: 5
});

const AllOrderList = () => {
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderColumns, setOrderColumns] = useState([{}]);
  const [failedListVisible, setFailedListVisible] = useState(false);
  const [uploadResultList, setUploadResultList] = useState([]);
  const [showAntdSearchBar, setShowAntdSearchBar] = useState(false);
  const [stateParams, setStateParams] = useState({ page: 1, pageNum: 20 });

  const mapRef = useRef({});
  const ordersRef = useRef([]);
  const updateFlag = useRef(false);
  const totalRef = useRef(0);
  const inSearchMode = useRef(false);
  const sortRef = useRef('default');
  const paramsUpdateRef = useRef(true);
  const lastFilter = useRef(null);
  const lastSearchParam = useRef('');
  const tableHasFilter = useRef(false);
  const actionRef = useRef();

  const fetchOrders = (tenantId, newParams) => {
    setLoading(true);
    let params = {
      ...newParams,
      tenantId: tenantId,
      sort: sortRef.current
    }
    return getOrders(params).then((res) => {
      let newOrders = [];
      if (res === null) {
        setLoading(false);
        return {
          data: [],
          success: true,
          total: 0
        }
      } else {
        // 整理column
        let columns = [];
        if (res.orderColumns !== null) {
          let findDisplayStatusIndex = res.orderColumns.findIndex((item) => {
            return item.dataIndex === 'displayStatus';
          });
          if (findDisplayStatusIndex !== -1) {
            res.orderColumns[findDisplayStatusIndex] = Object.assign({}, res.orderColumns[findDisplayStatusIndex], {
              filters: [
                {
                  text: '已同步到SFR',
                  value: 'Y'
                },
                {
                  text: '未同步到SFR',
                  value: 'N'
                },
                {
                  text: '已同步到ERP',
                  value: 'alreadySyncERP'
                },
                {
                  text: '未同步到ERP',
                  value: 'needSyncERP'
                },
                {
                  text: '託外無法回填ERP', // 託外
                  value: 'cannotSyncToCus'
                },
                {
                  text: '無需同步',
                  value: 'X'
                }
              ],
              onFilter: (value, record) => {
                if (value === 'Y') {
                  return record.displayStatus === 'Y';
                } else if (value === 'alreadySyncERP') {
                  return record.displayStatus === 'alreadySyncERP';
                } else if (value === 'needSyncERP') {
                  return record.displayStatus === 'needSyncERP';
                } else if (value === 'X') {
                  return record.displayStatus === 'X';
                } else if (value === 'cannotSyncToCus') { // 託外
                  return record.displayStatus === 'cannotSyncToCus';
                } else {
                  return record.displayStatus === 'N' || record.displayStatus === 'R' || record.displayStatus === 'needUpdateWoohToSfr';
                }
              },
              render: (text, record) => {
                let status = {
                  'Y': {
                    text: '已同步到SFR',
                    status: 'success'
                  },
                  'alreadySyncERP': {
                    text: '已同步至ERP',
                    status: 'success'
                  },
                  'N': {
                    text: '未同步到SFR',
                    status: 'default'
                  },
                  'R': {
                    text: '未同步到SFR',
                    status: 'default'
                  },
                  'needSyncERP': {
                    text: '未同步到ERP',
                    status: 'default'
                  },
                  'X': {
                    text: '無需同步',
                    status: 'error'
                  },
                  'needUpdateWoohToSfr': {
                    text: '未更新到SFR',
                    status: 'default'
                  },
                  'cannotSyncToCus': { // 託外
                    text: '託外無法回填ERP',
                    status: 'error'
                  },
                  'comparing': {
                    text: '比對中...'
                  }
                };

                return (

                  <Badge style={{ display: 'flex', alignItems: 'center' }}
                    status={status[record.displayStatus].status}
                    text={
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div> {status[record.displayStatus].text} </div>
                        <div style={{ marginLeft: '8px' }}>
                          <QuestionCircleOutlined
                            onClick={() => {
                              Modal.info({
                                title: "目前同步進度",
                                content: (
                                  <div style={{ margin: '24px 0px' }}>
                                    <OrderLocation displayStatus={record.displayStatus} reason={record.hasOwnProperty('reason') ? record.reason : null} />
                                  </div>
                                ),
                                width: '40vw',
                                okText: "關閉",
                                centered: true
                              });
                            }}
                          />
                        </div>
                      </div>
                    }
                  />
                );
              }
            });
          }
          // 調整工單狀態的顏色 進行中/完工
          columns = res.orderColumns.map((item) => {
            if (item.hasOwnProperty('render') && orderColumnKeyMap.hasOwnProperty(item.dataIndex)) {
              return Object.assign({}, item, {
                render: orderColumnKeyMap[item.dataIndex]
              })
            }
            if (item.timeFlag) {
              return Object.assign({}, item,
                {
                  render: (val) => {
                    return dayjs(val).isValid() ? dayjs(val).format('YYYY-MM-DD') : '';
                  },
                  sorter: (a, b) => dayjs(a[item.dataIndex]).isAfter(b[item.dataIndex]) === true ? 1 : -1,
                  sortDirections: ['descend', 'ascend']
                }
              );
            } else if (item.dataIndex === 'woId') {
              return Object.assign({}, item,
                {
                  sorter: (a, b) => {
                    if (a[item.dataIndex] > b[item.dataIndex]) {
                      return 1;
                    } else {
                      return -1;
                    }
                  },
                  sortDirections: ['descend', 'ascend']
                }
              );
            } else if (item.dataIndex === 'reason') {
              return Object.assign({}, item,
                {
                  render: (val) => {
                    return reasonKeyMap[val];
                  },
                }
              );
            } else {
              return item;
            }
          })
          newOrders = res.orders.data.map((item, index) => {
            let displayStatus = item.isUploadWhah === 'Y' ? item.isUploadOrder : 'N'
            return Object.assign(item, { key: index, displayStatus: displayStatus });
          })
          setOrderColumns(columns);
          setShowAntdSearchBar(true);
          setOrders(newOrders);
          setLoading(false);
          mapRef.current = res.dzDataMap;
        }
        totalRef.current = res.orders.total;
        return {
          data: newOrders,
          success: true,
          total: res.orders.total
        }
      }
    }).catch((err) => {
      console.log('Order - get order from customer', err)
      message.error('網路異常，請稍後重試。');
      setOrders([]);
      setLoading(false);
      totalRef.current = 0;
      return {
        data: [],
        success: true,
        total: 0
      }
    })
  }

  const handleUpload = async (selectedRows) => {
    // console.log('selectedRows', selectedRows)
    setLoading(true);

    updateFlag.current = true;

    let newOrders = Array.from(orders);
    ordersRef.current = newOrders;
    let needUpload = [];
    let updateDataMap = {}
    for (let rowIndex = 0; rowIndex < selectedRows.length; rowIndex++) {
      // 一個一個查是否上傳過
      let row = selectedRows[rowIndex];
      if (row.displayStatus === 'Y' || row.displayStatus === 'alreadySyncERP') {
        message.warning(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} 工單已同步過`);
        continue;
      } else if (row.displayStatus === 'X') {
        message.error(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} 工單無需同步`);
        continue;
        // } else if( row.displayStatus === 'cannotSyncToCus' ) { // 託外
        //   message.error(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName'] } 託外工單無法回填ERP`);
        //   continue;
      } else {
        if (row.route.length === 0) {
          message.warning(`${row['woId']} ${row['partNumber'] === null ? "" : row['partNumber']} ${row['prodName']} ERP工單資料異常`);
        } else {
          needUpload.push(row);
          if (mapRef.current.hasOwnProperty(row.woId)) {
            updateDataMap[row.woId] = mapRef.current[row.woId];
          }
        }
      }
    }
    if (needUpload.length > 0) {
      // 全部丟給後端處理
      let uploadResult = null;
      try {
        uploadResult = await uploadWorkOrderToDZ({
          selectedWorkOrders: needUpload,
          dzDataMap: updateDataMap,
          tenantId: userContext.tenantId
        });
      } catch (e) {
        console.log('Order - handleUpload', e)
        message.error({
          content: `上傳失敗`
        });

        uploadResult = {
          success: [],
          failed: []
        }
        needUpload.forEach((item) => {
          uploadResult.failed.push(item.woId);
        });
        setUploadResultList(uploadResult);
        setFailedListVisible(true);
      }

      if (uploadResult !== null) {
        if (uploadResult.success.length !== 0) {
          for (const woId of uploadResult.success) {
            let index = newOrders.findIndex((element) => {
              return element.woId === woId
            })
            let newUploadOrder = '';
            switch (newOrders[index].displayStatus) {
              case 'N':
                newUploadOrder = 'Y';
                break;
              case 'R':
                newUploadOrder = 'Y';
                break;
              case 'needSyncERP':
                newUploadOrder = 'alreadySyncERP';
                break;
              case 'needUpdateWoohToSfr':
                newUploadOrder = 'Y';
                break;
              // case 'cannotSyncToCus': // 託外
              //   newUploadOrder = 'Y';
              //   break;
              default:
                newUploadOrder = newOrders[index].displayStatus;
            }

            let newData = Object.assign({}, newOrders[index], { displayStatus: newUploadOrder })
            newOrders.splice(index, 1, newData);
          }

          if (uploadResult.failed.length === 0) {
            // 全成功
            message.success({
              content: `上傳成功`
            });
          } else {
            // 一半成功 一半失敗
            setUploadResultList(uploadResult);
            setFailedListVisible(true);
          }
        } else {
          // 全失敗
          if (uploadResult.failed.length !== 0) {
            message.error({
              content: `上傳失敗`
            });
            setUploadResultList(uploadResult);
            setFailedListVisible(true);
          }
        }
      }
    }
    actionRef.current.reload();
    actionRef.current.clearSelected();
    setOrders(newOrders);
    setLoading(false);
  }

  const handleChange = (pagination, filters, sorter) => {
    actionRef.current.clearSelected();
    paramsUpdateRef.current = false;

    let hasFilter = false;
    for (const value of Object.values(filters)) {
      if (value !== null) {
        hasFilter = true;
      }
    }
    tableHasFilter.current = hasFilter;

    let newParams = {};
    for (let [key, value] of Object.entries(pagination)) {
      key = (key === 'current') ? 'page' : key;
      key = (key === 'pageSize') ? 'pageNum' : key;
      if (key === 'page') {
        newParams[key] = value;
      }
      if (key === 'pageNum') {
        newParams[key] = value;
      }
    }

    if (stateParams.hasOwnProperty('pageNum') && newParams.hasOwnProperty('pageNum') && stateParams['pageNum'] !== newParams['pageNum']) {
      newParams.page = 1;
    }
    if (Object.entries(sorter).length > 0 && sorter['field'] !== undefined && sorter['order'] !== undefined) {
      if (sortRef.current !== `${sorter['field']},${sorter['order'] === 'descend' ? 'DESC' : 'ASC'}`) {
        paramsUpdateRef.current = true && !hasFilter;
      }

      sortRef.current = `${sorter['field']},${sorter['order'] === 'descend' ? 'DESC' : 'ASC'}`
    } else {
      if (sortRef.current !== 'default') {
        paramsUpdateRef.current = true && !hasFilter;
      }
      sortRef.current = 'default';
    }
    if (!paramsUpdateRef.current) {
      for (const [key, value] of Object.entries(newParams)) {
        if (!stateParams.hasOwnProperty(key)) {
          paramsUpdateRef.current = true;
          break;
        } else {
          if (stateParams[key] !== value) {
            if (key === 'page' || key === 'pageNum') {
              if (!hasFilter) {
                paramsUpdateRef.current = true;
                break;
              } else {
                paramsUpdateRef.current = false;
              }
            } else {
              paramsUpdateRef.current = true;
              break;
            }
          } else {
            if (key === 'page' || key === 'pageNum') {
              paramsUpdateRef.current = false;
            }
          }
        }
      }
    }

    if (lastFilter.current !== null && (lastFilter.current && !hasFilter)) {
      paramsUpdateRef.current = true;
    }

    lastFilter.current = hasFilter;

    setStateParams(newParams);
  }

  const requestData = async (params, sorter, filter) => {
    if (updateFlag.current === false) {
      let hasSearchParam = false;
      let newParams = {};
      let currentSearchParam = '';
      for (let [key, value] of Object.entries(params)) {
        key = (key === 'current') ? 'page' : key;
        key = (key === 'pageSize') ? 'pageNum' : key;
        if (typeof (value) === 'number') {
          newParams[key] = value;
        }
        if (typeof (value) === 'string') {
          if (value.trim() !== '') {
            newParams[key] = value.trim();
            hasSearchParam = true;
            currentSearchParam = [currentSearchParam, `${key},${value.trim()}`].join("//");
          }
        }
        if (key === 'startTime' && value !== undefined) {
          newParams['startDate'] = dayjs(value[0]).startOf('date').tz("Asia/Taipei").unix();
          newParams['endDate'] = dayjs(value[1]).tz("Asia/Taipei").startOf('date').unix();
          currentSearchParam = [currentSearchParam, `${key},${newParams['startDate']},${newParams['endDate']}`].join("//");
          // 英鈿搜尋時使用startTime排序，把sortRef.current = default改為startTime。
          sortRef.current = key;
        }
      }
      if ((hasSearchParam && inSearchMode.current === false) || lastSearchParam.current !== currentSearchParam) {
        Object.assign(newParams, { page: 1 });
        inSearchMode.current = true;
        paramsUpdateRef.current = true;

        lastSearchParam.current = currentSearchParam;
      }

      let data = { data: Array.from(orders, item => item), success: true, total: totalRef.current };
      if (paramsUpdateRef.current) {
        data = await fetchOrders(userContext.tenantId, newParams);
        // for ( let item of data.data ) {
        //   item.isUploadOrder = '';
        // }
      }

      if (Object.entries(filter).length > 0) {
        for (const [key, value] of Object.entries(filter)) {
          if (value !== null) {

            data.data = data.data.filter((item) => {
              return value.find((filterValue) => {
                return filterValue === item[key];
              }) !== undefined ? true : false;
            })
            data.total = data.data.length;
          }
        }
      }

      return {
        data: data.data,
        success: true,
        total: data.total
      };
    } else {
      updateFlag.current = false;
      let data = { data: Array.from(orders, item => item), success: true, total: totalRef.current };
      if (Object.entries(filter).length > 0) {
        for (const [key, value] of Object.entries(filter)) {
          if (value !== null) {
            data.data = data.data.filter((item) => {
              return value.find((filterValue) => {
                return filterValue === item[key];
              }) !== undefined ? true : false;
            })
            data.total = data.data.length;
          }
        }
      }

      return data;
    }
  };

  return (
    <div>
      <ProTable
        headerTitle="製令單匯入"
        onReset={
          () => {
            inSearchMode.current = false;
            paramsUpdateRef.current = true;
            lastSearchParam.current = '';
            setStateParams(Object.assign({}, stateParams, { current: 1, page: 1 }))
          }
        }
        loading={loading}
        actionRef={actionRef}
        rowKey="key"
        toolBarRender={(action, { selectedRows }) => [
          selectedRows && selectedRows.length > 0 && (
            <Button onClick={
              () => {
                handleUpload(selectedRows);
              }
            }>
              上傳工單
            </Button>
          ),
        ]}
        columns={orderColumns}
        params={stateParams}
        rowSelection={{}}
        search={showAntdSearchBar}
        request={requestData}
        postData={(data) => {
          return data;
        }}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 50, 100],
          showQuickJumper: true,
          showTotal: (total) => {
            if (tableHasFilter.current) {
              return `本頁篩選結果為 ${total} 筆`
            } else {
              return `共 ${total} 筆`
            }
          }
        }}
        onChange={handleChange}
      />
      <FailedList
        uploadResultList={uploadResultList}
        failedListVisible={failedListVisible}
        setFailedListVisible={setFailedListVisible}
      />
    </div>
  );
};

export default AllOrderList;