import axios from 'axios';
import Cookies from 'js-cookie';

let request;

export const init_request = () => {
  // basic axios instance
  request = axios.create({
    baseURL: process.env.REACT_APP_ERP_SYNC_TOOL_API
  });

  // set interceptors for request
  request.interceptors.request.use((config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // set interceptors for response
  request.interceptors.response.use((req) => {
    return req;
  }, (error) => {
    if (error.response) {
      if (error.response.status.toString() === '400' || error.response.status.toString() === '401') {
        if (sessionStorage.getItem('reloadUrl') !== undefined) {
          let reloadUrl = process.env.REACT_APP_LOGIN_URL + sessionStorage.getItem('reloadUrl') + window.location.href;
          window.location.replace(reloadUrl);
        }
      }
    }
    return Promise.reject(error);
  });
};

// ----------------- ORDERS   -----------------
export const getOrders = (data) => {
  return request.get('/orders', {
    params: data
  }).then((res) => {
    return res.data;
  });
};

export const getOrdersByKey = (type, data) => {
  return request.get(`/orders/search/${type}`, {
    params: data
  }).then((res) => {
    return res.data;
  });
};

export const uploadWorkOrderToDZ = (data) => {
  return request.post('syncWorkOrder', data).then((res) => {
    return res.data;
  });
};

// ----------------- PRODUCTS -----------------
export const getProducts = (data) => {
  return request.get('/products', {
    params: data
  }).then((res) => {
    return res.data;
  });
};

// ----------------- JOBS -----------------
export const listJobs = (params) => {
  return request.get('/jobs', {
    params: params
  }).then((res) => {
    return res.data;
  });
};

export const createJob = (data, tenantId) => {
  return request.post('/job', data, {
    params: { tenantId: tenantId }
  }).then((res) => {
    return res;
  });
};

export const deleteJob = (data, tenantId) => {
  return request.delete('/job', {
    params: { tenantId: tenantId },
    data: data
  }).then((res) => {
    return res;
  });
};

export const getHistorys = (data) => {
  return request.get('/historys', {
    params: data
  }).then((res) => {
    return res.data;
  });
};

export const getHistorysBySearch = (data) => {
  return request.get(`/historys/search`, {
    params: data
  }
  ).then((res) => {
    return res.data;
  });
};

export const updateSfrToErpStatus = (data, tenantId) => {
  return request.patch('/sfrToErpFlag', data, {
    params: { tenantId: tenantId }
  }).then((res) => {
    return res;
  });
};

// ----------------- UNUSUALS -----------------
export const getUnusuals = (data) => {
  return request.get('/unusuals', {
    params: data
  }).then((res) => {
    return res.data;
  });
};

export const updateUnusualResolveTime = (data) => {
  return request.patch('/unusuals/resolveTime', data
  ).then((res) => {
    return res.data;
  });
}

export const getUnusualsBySearch = (data) => {
  return request.get(`/unusuals/search`, {
    params: data
  }
  ).then((res) => {
    return res.data;
  });
};